import React from "react"

const AboutSite = () => <div className="about-site">
    <h2>About This Site</h2>
    <p>
        I always wanted to have some home page in the Internet where I can make experiments and share some
        thoughts. I
        also like to revamp the CSS and HTML from time to time and apply best techniques and practices in order to
        keep
        it up to date. The back end of this site is built on top of PHP and wonderful Yii framework that I have
        been
        working with for a very long time, many thanks to Yii team for making it so flexible and powerful. I tend
        to use
        SASS with Compass for CSS and no frameworks for HTML so far. The RSS feed is generated with Zend Feed and
        FeedBurner. I also use Lora and PT Sans fonts by Google.
    </p>
</div>;

export default AboutSite;
