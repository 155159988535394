import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AboutSite from "../../components/about-site";

import { FaTwitter, FaStackOverflow, FaGithub, FaLinkedin } from 'react-icons/fa';

const description = `
About Rinat Silnov.
A brief story of what I did, what I do and what I believe in.
Short summary about this site. Contact Details.
`;

const keywords = ['Rinat Silnov', 'rinatio', 'web developer', 'front end developer', 'contact details'];


const SecondPage = () => (
    <Layout>
        <SEO title="About Me - Rinat Silnov" description={description} keywords={keywords}/>
        <section className="about-me">
            <article>
                <h2>
                    Hi! My name is Rinat
                </h2>

                <p>
                    I'm a software engineer with several years of experience in full life-cycle development,
                    including analysis, design, management, development, deployment,
                    testing and maintenance of application software in web-based environment.
                </p>
                <p>
                    I worked on different projects such as B2B and e-business apps,
                    CRM systems, IoT services, productivity and analytics startups.
                    Have a sound knowledge of developing cloud based and on-premises software,
                    monolith and serverless architectures, MVC and single page applications with React,
                    AngularJS, Django and other frameworks.
                </p>

                {/*<?= $this->render('../site/_about_site'); ?>*/}
                <AboutSite />
                <p>

                    <h2>Contact Me</h2>
                    <ul className="list-arrow-style">
                        <li>
                            If you are an employer and you'd like me to be a part of your awesome team, please
                            find my printable <a href="/about/resume">resume online</a> and{' '}
                            <a href="mailto:mail@rinat.io">send an email</a>.
                        </li>
                        <li>
                            If you just want to get in touch, please also <a href="mailto:mail@rinat.io">drop me a
                            line</a>,
                            I will be happy to hear from you.
                        </li>
                    </ul>
                    <p>
                        You can also find me in the following social networks:
                    </p>
                    <table className="social-links">
                        <tr>
                            <td>
                                <a href="https://twitter.com/rinat_io"><FaTwitter className="social-icon"/></a>
                            </td>
                            <td>
                                <a href="https://github.com/rinatio"><FaGithub className="social-icon" /></a>
                            </td>
                            <td>
                                <a href="http://stackoverflow.com/users/1224211/rinat-io"><FaStackOverflow className="social-icon"/></a>
                            </td>
                            <td>
                                <a href="https://www.linkedin.com/in/rinatio"><FaLinkedin className="social-icon"/></a>
                            </td>
                        </tr>
                    </table>
                </p>
            </article>
        </section>
    </Layout>
);

export default SecondPage
